/* App.css */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  height: 100vh;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.header {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 20px;
  display: flex;
  gap: 15px;
}

.header-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1.2em;
  text-decoration: none;
  cursor: pointer;
}

.header-button:hover {
  background-color: #0056b3;
}

.login-box {
  position: absolute;
  top: 200px;
  left: 47.5%;
  transform: translateX(-50%);
  padding: 20px;
  border-radius: 10px;
}

.language-selection-box {
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  width: 350px;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.login-box form, .language-selection-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.login-box input,
.login-box button {
  display: block;
  width: 130%;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px;
  font-size: 1.2em;
  box-sizing: border-box;
}

.login-box input {
  border: 1px solid #ccc;
}

.login-box button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.login-box button:hover {
  background-color: #0056b3;
}

.language-selection-box button {
  display: block;
  width: 100%;
  max-width: 350px;
  margin: 10px auto;
  padding: 10px;
  border-radius: 5px;
  font-size: 1.2em;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
}

.language-selection-box button:hover {
  background-color: #0056b3;
}

.translation-verification {
  display: flex;
  width: 100%;
  height: 100vh;
}

.side-menu {
  width: 250px;
  background-color: #f7f7f7;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  flex-shrink: 0;
}

.side-menu h3 {
  margin-top: 0;
}

.side-menu ul {
  list-style: none;
  padding: 0;
}

.side-menu ul li {
  padding: 10px;
  cursor: pointer;
}

.side-menu ul li.active {
  background-color: #007bff;
  color: white;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.phrase-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  max-width: 1000px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.phrase,
.translation {
  font-size: 28px;
  font-weight: bold;
  flex: 1;
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin-top: 20px;
}

.correct,
.wrong,
.not-sure {
  font-size: 24px;
  padding: 20px;
  margin: 10px;
  border: none;
  cursor: pointer;
  flex: 0 0 auto;
}

.correct {
  background-color: green;
  color: white;
}

.wrong {
  background-color: red;
  color: white;
}

.not-sure {
  background-color: grey;
  color: white;
}

.edit-container {
  margin-top: 20px;
}

.edit-container input {
  font-size: 24px;
  padding: 10px;
}

.edit-container button {
  font-size: 24px;
  padding: 10px 20px;
  margin-left: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  color: white;
  font-size: 5rem;
}

.side-menu h3 {
  margin-top: 20px; /* Add padding above the header */
  margin-bottom: 20px; /* Add padding below the header */
}

.status-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #f7f7f7;
  border-top: 1px solid #ccc;
  text-align: center;
  font-size: 1.2em;
}

.add-phrase-container {
  padding-top: 20px;
}

.add-phrase-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.audio-recorder-button {
  padding: 10px 20px;
  font-size: 16px;
}

.audio-recorder-button.record-again {
  background-color: grey;
  color: white;
  margin-right: 10px;
}

.audio-recorder-button.submit-and-continue {
  background-color: green;
  color: white;
}
